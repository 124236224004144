@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Poppins:ital,wght@0,200;0,300;0,400;0,600;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Poppins:ital,wght@0,200;0,300;1,400&display=swap');

body, html {
  height: 100%;
  margin: 0; 
}
body {
  font-family: "Quicksand", sans-serif;
  background-image: url('./images/bg_sistema.png') ;
  background-repeat: no-repeat;
  background-size: cover;
}
.icone {
  color: blue;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 2%;
}
.previous-tickets-list {
  list-style: none;
  padding: 0;
  max-height: 65%;
  overflow-y: scroll;
}

.previous-tickets-list-title {
  background-color: black;
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 300px;
  border-radius: 9px;
  padding: 5px;
  text-align: center;
}

.number-changed {
  border: 2px solid black;
  border-radius: 17px;
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 300px;
  position: relative;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  max-height: 437px;
}

.number-changed::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -25px; 
  border: 15px solid transparent; 
  border-right-color:  black; 
  transform: translateY(-50%);
}
.number-changed1::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -25px; 
  border: 15px solid transparent; 
  border-right-color:  black; 
  transform: translateY(-50%);
}
.number-changed2::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -30px; 
  border: 15px solid transparent; 
  border-right-color:  black; 
  transform: translateY(-50%);
}

.poppins-gold{
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  
}
.bg-text{
  background-color: black;
  color: #fff;
  border-radius: 17px;
  padding: 5px;
  margin: 5px;
}
.poppins{
  font-family: "Poppins", sans-serif;
}
.fontSize{
  font-size: 1.5em;
}
.fontSizeList{
  color: #fff;
}
.ultimos-chamados {
  position: fixed;
  right: 0;
  height: 150%;
}
.ultimos-chamados {
  position: fixed;
  right: 0;
  height: 150%;
}
.sos{
  flex: '1'; 
  display: 'flex'; 
  flex-direction: 'column'; 
  align-items: 'center';
  justify-content: 'center'
}
.chamado-atual{
  flex: 0 0 auto; 
  margin-left: 19%; 
  width: 50%;
  max-width: 80%;
}
.chamado-atual.mobile {
  font-size: 0.5em;
  margin-top: 10px;
}
.menu-icon {
  cursor: pointer;
  display: flex;
  align-items: center; 
  justify-content: space-between;
  margin: 0 10px;
}
.logo-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px; 
  width: 20%;
}
.logo-neat{
  max-width: 100%;
  height: auto;
  margin-top: 20px;
  width: 11%;
  margin-left: 5%;
}
.logo-div{
  justify-content: center;
  align-items: center;
  display: flex;
}

.container.mobile {
  display: flex;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 47%;
}

@media only screen and (max-width: 768px) {
  .ultimos-chamados-mobile {
    position: relative;
    height: auto; 
  }
  .chamado-atual {
    margin-left: 5%; 
    width: 90%;
  }
  .logo-image {
    width: 50%; 
  }
  .sos, .chamado-atual, .ultimos-chamados {
    width: 100%; 
  }
  .mobile-list {
    display: flex;
    flex-direction: row;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1204px) {
  .chamado-atual {
    margin-left: 10%; 
    width: 80%;
  }
  .sos, .chamado-atual, .ultimos-chamados {
    width: 33.33%; 
    float: left; 
  }
}